// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-colin-mdx": () => import("./../../../src/pages/about/colin.mdx" /* webpackChunkName: "component---src-pages-about-colin-mdx" */),
  "component---src-pages-about-earthworks-mdx": () => import("./../../../src/pages/about/earthworks.mdx" /* webpackChunkName: "component---src-pages-about-earthworks-mdx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-build-time-tsx": () => import("./../../../src/pages/build-time.tsx" /* webpackChunkName: "component---src-pages-build-time-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-gondwana-aac-anim-1-mdx": () => import("./../../../src/pages/gondwana/aac-anim-1.mdx" /* webpackChunkName: "component---src-pages-gondwana-aac-anim-1-mdx" */),
  "component---src-pages-gondwana-africa-antarctica-mdx": () => import("./../../../src/pages/gondwana/africa-antarctica.mdx" /* webpackChunkName: "component---src-pages-gondwana-africa-antarctica-mdx" */),
  "component---src-pages-gondwana-animations-mdx": () => import("./../../../src/pages/gondwana/animations.mdx" /* webpackChunkName: "component---src-pages-gondwana-animations-mdx" */),
  "component---src-pages-gondwana-articulated-africa-mdx": () => import("./../../../src/pages/gondwana/articulated-africa.mdx" /* webpackChunkName: "component---src-pages-gondwana-articulated-africa-mdx" */),
  "component---src-pages-gondwana-course-tsx": () => import("./../../../src/pages/gondwana/course.tsx" /* webpackChunkName: "component---src-pages-gondwana-course-tsx" */),
  "component---src-pages-gondwana-east-versus-west-mdx": () => import("./../../../src/pages/gondwana/east-versus-west.mdx" /* webpackChunkName: "component---src-pages-gondwana-east-versus-west-mdx" */),
  "component---src-pages-gondwana-ego-aac-plates-mdx": () => import("./../../../src/pages/gondwana/ego-aac-plates.mdx" /* webpackChunkName: "component---src-pages-gondwana-ego-aac-plates-mdx" */),
  "component---src-pages-gondwana-fixed-is-fixed-mdx": () => import("./../../../src/pages/gondwana/fixed-is-fixed.mdx" /* webpackChunkName: "component---src-pages-gondwana-fixed-is-fixed-mdx" */),
  "component---src-pages-gondwana-guinea-1-mdx": () => import("./../../../src/pages/gondwana/guinea-1.mdx" /* webpackChunkName: "component---src-pages-gondwana-guinea-1-mdx" */),
  "component---src-pages-gondwana-index-tsx": () => import("./../../../src/pages/gondwana/index.tsx" /* webpackChunkName: "component---src-pages-gondwana-index-tsx" */),
  "component---src-pages-gondwana-large-igneous-provinces-mdx": () => import("./../../../src/pages/gondwana/large-igneous-provinces.mdx" /* webpackChunkName: "component---src-pages-gondwana-large-igneous-provinces-mdx" */),
  "component---src-pages-gondwana-latest-rotation-mdx": () => import("./../../../src/pages/gondwana/latest-rotation.mdx" /* webpackChunkName: "component---src-pages-gondwana-latest-rotation-mdx" */),
  "component---src-pages-gondwana-legend-to-the-anims-mdx": () => import("./../../../src/pages/gondwana/legend-to-the-anims.mdx" /* webpackChunkName: "component---src-pages-gondwana-legend-to-the-anims-mdx" */),
  "component---src-pages-gondwana-making-ends-meet-mdx": () => import("./../../../src/pages/gondwana/making-ends-meet.mdx" /* webpackChunkName: "component---src-pages-gondwana-making-ends-meet-mdx" */),
  "component---src-pages-gondwana-mar-mag-anom-mdx": () => import("./../../../src/pages/gondwana/mar-mag-anom.mdx" /* webpackChunkName: "component---src-pages-gondwana-mar-mag-anom-mdx" */),
  "component---src-pages-gondwana-mid-ocean-ridges-mdx": () => import("./../../../src/pages/gondwana/mid-ocean-ridges.mdx" /* webpackChunkName: "component---src-pages-gondwana-mid-ocean-ridges-mdx" */),
  "component---src-pages-gondwana-moz-plains-mdx": () => import("./../../../src/pages/gondwana/moz-plains.mdx" /* webpackChunkName: "component---src-pages-gondwana-moz-plains-mdx" */),
  "component---src-pages-gondwana-nacgea-april-2021-mdx": () => import("./../../../src/pages/gondwana/nacgea-april-2021.mdx" /* webpackChunkName: "component---src-pages-gondwana-nacgea-april-2021-mdx" */),
  "component---src-pages-gondwana-nacgeo-march-2020-mdx": () => import("./../../../src/pages/gondwana/nacgeo-march-2020.mdx" /* webpackChunkName: "component---src-pages-gondwana-nacgeo-march-2020-mdx" */),
  "component---src-pages-gondwana-oceansonlygrow-mdx": () => import("./../../../src/pages/gondwana/oceansonlygrow.mdx" /* webpackChunkName: "component---src-pages-gondwana-oceansonlygrow-mdx" */),
  "component---src-pages-gondwana-other-material-mdx": () => import("./../../../src/pages/gondwana/other-material.mdx" /* webpackChunkName: "component---src-pages-gondwana-other-material-mdx" */),
  "component---src-pages-gondwana-pesgb-africa-2019-mdx": () => import("./../../../src/pages/gondwana/pesgb-africa-2019.mdx" /* webpackChunkName: "component---src-pages-gondwana-pesgb-africa-2019-mdx" */),
  "component---src-pages-gondwana-south-atlantic-poster-mdx": () => import("./../../../src/pages/gondwana/south-atlantic-poster.mdx" /* webpackChunkName: "component---src-pages-gondwana-south-atlantic-poster-mdx" */),
  "component---src-pages-gondwana-sri-lanka-2-mdx": () => import("./../../../src/pages/gondwana/sri-lanka-2.mdx" /* webpackChunkName: "component---src-pages-gondwana-sri-lanka-2-mdx" */),
  "component---src-pages-gondwana-starting-data-mdx": () => import("./../../../src/pages/gondwana/starting-data.mdx" /* webpackChunkName: "component---src-pages-gondwana-starting-data-mdx" */),
  "component---src-pages-gondwana-twelve-lectures-mdx": () => import("./../../../src/pages/gondwana/twelve-lectures.mdx" /* webpackChunkName: "component---src-pages-gondwana-twelve-lectures-mdx" */),
  "component---src-pages-gondwana-updates-mdx": () => import("./../../../src/pages/gondwana/updates.mdx" /* webpackChunkName: "component---src-pages-gondwana-updates-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-16th-african-petroleum-conference-mdx": () => import("./../../../src/pages/news/16th-african-petroleum-conference.mdx" /* webpackChunkName: "component---src-pages-news-16th-african-petroleum-conference-mdx" */),
  "component---src-pages-news-aapg-lecture-tour-europe-mdx": () => import("./../../../src/pages/news/aapg-lecture-tour-europe.mdx" /* webpackChunkName: "component---src-pages-news-aapg-lecture-tour-europe-mdx" */),
  "component---src-pages-news-african-geodynamics-courses-mdx": () => import("./../../../src/pages/news/african-geodynamics-courses.mdx" /* webpackChunkName: "component---src-pages-news-african-geodynamics-courses-mdx" */),
  "component---src-pages-news-african-petroleum-geologists-mdx": () => import("./../../../src/pages/news/african-petroleum-geologists.mdx" /* webpackChunkName: "component---src-pages-news-african-petroleum-geologists-mdx" */),
  "component---src-pages-news-alan-smith-mini-symposium-mdx": () => import("./../../../src/pages/news/alan-smith-mini-symposium.mdx" /* webpackChunkName: "component---src-pages-news-alan-smith-mini-symposium-mdx" */),
  "component---src-pages-news-congresso-brasileiro-de-geologia-mdx": () => import("./../../../src/pages/news/congresso-brasileiro-de-geologia.mdx" /* webpackChunkName: "component---src-pages-news-congresso-brasileiro-de-geologia-mdx" */),
  "component---src-pages-news-earthworks-20-years-mdx": () => import("./../../../src/pages/news/earthworks-20-years.mdx" /* webpackChunkName: "component---src-pages-news-earthworks-20-years-mdx" */),
  "component---src-pages-news-earthworks-2001-2011-mdx": () => import("./../../../src/pages/news/earthworks-2001-2011.mdx" /* webpackChunkName: "component---src-pages-news-earthworks-2001-2011-mdx" */),
  "component---src-pages-news-earthworks-bv-or-not-bv-mdx": () => import("./../../../src/pages/news/earthworks-bv-or-not-bv.mdx" /* webpackChunkName: "component---src-pages-news-earthworks-bv-or-not-bv-mdx" */),
  "component---src-pages-news-earthworks-radio-new-york-mdx": () => import("./../../../src/pages/news/earthworks-radio-new-york.mdx" /* webpackChunkName: "component---src-pages-news-earthworks-radio-new-york-mdx" */),
  "component---src-pages-news-east-africa-margin-petroleum-geoscience-mdx": () => import("./../../../src/pages/news/east-africa-margin-petroleum-geoscience.mdx" /* webpackChunkName: "component---src-pages-news-east-africa-margin-petroleum-geoscience-mdx" */),
  "component---src-pages-news-fit-southern-continents-mdx": () => import("./../../../src/pages/news/fit-southern-continents.mdx" /* webpackChunkName: "component---src-pages-news-fit-southern-continents-mdx" */),
  "component---src-pages-news-geological-society-meet-addis-mdx": () => import("./../../../src/pages/news/geological-society-meet-addis.mdx" /* webpackChunkName: "component---src-pages-news-geological-society-meet-addis-mdx" */),
  "component---src-pages-news-geological-surveys-african-development-mdx": () => import("./../../../src/pages/news/geological-surveys-african-development.mdx" /* webpackChunkName: "component---src-pages-news-geological-surveys-african-development-mdx" */),
  "component---src-pages-news-geophysics-in-botswana-40-years-ago-mdx": () => import("./../../../src/pages/news/geophysics-in-botswana-40-years-ago.mdx" /* webpackChunkName: "component---src-pages-news-geophysics-in-botswana-40-years-ago-mdx" */),
  "component---src-pages-news-geosoft-celebrates-30-years-mdx": () => import("./../../../src/pages/news/geosoft-celebrates-30-years.mdx" /* webpackChunkName: "component---src-pages-news-geosoft-celebrates-30-years-mdx" */),
  "component---src-pages-news-gondwana-16-meet-bangkok-mdx": () => import("./../../../src/pages/news/gondwana-16-meet-bangkok.mdx" /* webpackChunkName: "component---src-pages-news-gondwana-16-meet-bangkok-mdx" */),
  "component---src-pages-news-gondwana-map-project-approved-mdx": () => import("./../../../src/pages/news/gondwana-map-project-approved.mdx" /* webpackChunkName: "component---src-pages-news-gondwana-map-project-approved-mdx" */),
  "component---src-pages-news-gondwana-map-workshop-rio-mdx": () => import("./../../../src/pages/news/gondwana-map-workshop-rio.mdx" /* webpackChunkName: "component---src-pages-news-gondwana-map-workshop-rio-mdx" */),
  "component---src-pages-news-gondwana-workshop-rio-mdx": () => import("./../../../src/pages/news/gondwana-workshop-rio.mdx" /* webpackChunkName: "component---src-pages-news-gondwana-workshop-rio-mdx" */),
  "component---src-pages-news-gravity-at-sea-mdx": () => import("./../../../src/pages/news/gravity-at-sea.mdx" /* webpackChunkName: "component---src-pages-news-gravity-at-sea-mdx" */),
  "component---src-pages-news-gravity-survey-origin-mankind-mdx": () => import("./../../../src/pages/news/gravity-survey-origin-mankind.mdx" /* webpackChunkName: "component---src-pages-news-gravity-survey-origin-mankind-mdx" */),
  "component---src-pages-news-how-mining-geophysics-got-started-mdx": () => import("./../../../src/pages/news/how-mining-geophysics-got-started.mdx" /* webpackChunkName: "component---src-pages-news-how-mining-geophysics-got-started-mdx" */),
  "component---src-pages-news-ian-macleod-cecil-green-award-mdx": () => import("./../../../src/pages/news/ian-macleod-cecil-green-award.mdx" /* webpackChunkName: "component---src-pages-news-ian-macleod-cecil-green-award-mdx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-news-india-passive-margins-a-new-model-mdx": () => import("./../../../src/pages/news/india-passive-margins-a-new-model.mdx" /* webpackChunkName: "component---src-pages-news-india-passive-margins-a-new-model-mdx" */),
  "component---src-pages-news-indian-ocean-history-in-print-mdx": () => import("./../../../src/pages/news/indian-ocean-history-in-print.mdx" /* webpackChunkName: "component---src-pages-news-indian-ocean-history-in-print-mdx" */),
  "component---src-pages-news-itc-delft-alma-mater-no-more-mdx": () => import("./../../../src/pages/news/itc-delft-alma-mater-no-more.mdx" /* webpackChunkName: "component---src-pages-news-itc-delft-alma-mater-no-more-mdx" */),
  "component---src-pages-news-itc-delft-staff-reunion-mdx": () => import("./../../../src/pages/news/itc-delft-staff-reunion.mdx" /* webpackChunkName: "component---src-pages-news-itc-delft-staff-reunion-mdx" */),
  "component---src-pages-news-london-talk-best-paper-mdx": () => import("./../../../src/pages/news/london-talk-best-paper.mdx" /* webpackChunkName: "component---src-pages-news-london-talk-best-paper-mdx" */),
  "component---src-pages-news-malawi-geophysical-interpretation-completed-mdx": () => import("./../../../src/pages/news/malawi-geophysical-interpretation-completed.mdx" /* webpackChunkName: "component---src-pages-news-malawi-geophysical-interpretation-completed-mdx" */),
  "component---src-pages-news-new-buildings-gsi-training-school-india-mdx": () => import("./../../../src/pages/news/new-buildings-gsi-training-school-india.mdx" /* webpackChunkName: "component---src-pages-news-new-buildings-gsi-training-school-india-mdx" */),
  "component---src-pages-news-new-dispersion-model-mdx": () => import("./../../../src/pages/news/new-dispersion-model.mdx" /* webpackChunkName: "component---src-pages-news-new-dispersion-model-mdx" */),
  "component---src-pages-news-new-refinements-east-gondwana-mdx": () => import("./../../../src/pages/news/new-refinements-east-gondwana.mdx" /* webpackChunkName: "component---src-pages-news-new-refinements-east-gondwana-mdx" */),
  "component---src-pages-news-news-of-gondwana-mdx": () => import("./../../../src/pages/news/news-of-gondwana.mdx" /* webpackChunkName: "component---src-pages-news-news-of-gondwana-mdx" */),
  "component---src-pages-news-nigeria-geophysical-coverage-update-mdx": () => import("./../../../src/pages/news/nigeria-geophysical-coverage-update.mdx" /* webpackChunkName: "component---src-pages-news-nigeria-geophysical-coverage-update-mdx" */),
  "component---src-pages-news-nigerian-airborne-survey-completed-mdx": () => import("./../../../src/pages/news/nigerian-airborne-survey-completed.mdx" /* webpackChunkName: "component---src-pages-news-nigerian-airborne-survey-completed-mdx" */),
  "component---src-pages-news-okavango-revisited-mdx": () => import("./../../../src/pages/news/okavango-revisited.mdx" /* webpackChunkName: "component---src-pages-news-okavango-revisited-mdx" */),
  "component---src-pages-news-oval-pear-shaped-mdx": () => import("./../../../src/pages/news/oval-pear-shaped.mdx" /* webpackChunkName: "component---src-pages-news-oval-pear-shaped-mdx" */),
  "component---src-pages-news-presentations-2023-mdx": () => import("./../../../src/pages/news/presentations-2023.mdx" /* webpackChunkName: "component---src-pages-news-presentations-2023-mdx" */),
  "component---src-pages-news-publications-update-mdx": () => import("./../../../src/pages/news/publications-update.mdx" /* webpackChunkName: "component---src-pages-news-publications-update-mdx" */),
  "component---src-pages-news-three-beers-mid-atlantic-fridge-mdx": () => import("./../../../src/pages/news/three-beers-mid-atlantic-fridge.mdx" /* webpackChunkName: "component---src-pages-news-three-beers-mid-atlantic-fridge-mdx" */),
  "component---src-pages-news-three-presentations-2021-mdx": () => import("./../../../src/pages/news/three-presentations-2021.mdx" /* webpackChunkName: "component---src-pages-news-three-presentations-2021-mdx" */),
  "component---src-pages-news-transit-of-mercury-mdx": () => import("./../../../src/pages/news/transit-of-mercury.mdx" /* webpackChunkName: "component---src-pages-news-transit-of-mercury-mdx" */),
  "component---src-pages-news-tribute-to-dave-hutchins-mdx": () => import("./../../../src/pages/news/tribute-to-dave-hutchins.mdx" /* webpackChunkName: "component---src-pages-news-tribute-to-dave-hutchins-mdx" */),
  "component---src-pages-news-tribute-to-dr-alan-smith-mdx": () => import("./../../../src/pages/news/tribute-to-dr-alan-smith.mdx" /* webpackChunkName: "component---src-pages-news-tribute-to-dr-alan-smith-mdx" */),
  "component---src-pages-news-two-new-animations-mdx": () => import("./../../../src/pages/news/two-new-animations.mdx" /* webpackChunkName: "component---src-pages-news-two-new-animations-mdx" */),
  "component---src-pages-news-two-talks-gondwana-15-mdx": () => import("./../../../src/pages/news/two-talks-gondwana-15.mdx" /* webpackChunkName: "component---src-pages-news-two-talks-gondwana-15-mdx" */),
  "component---src-pages-publications-comprehensive-list-mdx": () => import("./../../../src/pages/publications/comprehensive-list.mdx" /* webpackChunkName: "component---src-pages-publications-comprehensive-list-mdx" */),
  "component---src-pages-publications-gondwana-publications-mdx": () => import("./../../../src/pages/publications/gondwana-publications.mdx" /* webpackChunkName: "component---src-pages-publications-gondwana-publications-mdx" */),
  "component---src-pages-publications-index-tsx": () => import("./../../../src/pages/publications/index.tsx" /* webpackChunkName: "component---src-pages-publications-index-tsx" */),
  "component---src-pages-publications-key-publications-mdx": () => import("./../../../src/pages/publications/key-publications.mdx" /* webpackChunkName: "component---src-pages-publications-key-publications-mdx" */)
}

